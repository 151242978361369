import React from "react";
import Banner from "../components/Banner";
import WhatServiceDo from "../components/service/WhatServiceDo";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Cta from "../components/service/Cta";
import ServiceTab from "../components/ServiceTab";
import Clients from "../components/service/Clients";
import Testimonials from "../components/Testimonials";
import ProjectSection from "../components/ProjectSection";
import Faq from "../components/Faq";
// import KnowMoreSection from "../components/KnowMoreSection";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";

const ServiceDetail = () => {
  const banner = {
    title: `<span class="h1-span">Expert User</span><span class="h1-span"> Acquisition Agency</span>`,
    content:
      "We are a leading customer acquisition company adopting unconventional and modern strategies to acquire users for your digital products.",
  };
  const whatServiceContent = {
    title: "What does the User Acquisition Agency do?",
    content: [
      "A User Acquisition consultation agency specializes in helping businesses attract and retain new customers through targeted, data-driven strategies.",
      "These agencies leverage cutting-edge techniques to optimize user acquisition across various digital channels.",
      "From campaign planning and audience research to performance tracking and optimization, the agency's user acquisition services cover the full spectrum of customer acquisition needs.",
      "Their team of experts develops customized customer acquisition solutions that drive sustainable growth and maximize return on investment."
    ],
    img: ["what-service-image-user-acquisition.webp"],
    imageTitle: 'User Acquisition Consultation',
    imageAlt: '',
  };

  const commonContent = {
    title: `Who needs User <span class="h1-span">User Acquisition Services?</span>`,
    para: "Businesses of all sizes require customer acquisition services to expand their customer base and drive growth. Organizations can benefit from user acquisition consultation from startups to enterprises to attract broader audiences and win high-value customers.",
    img: ["who-needs-user-acquisition.webp"],
    imageTitle: 'User Acquisition Services',
    imageAlt: '',
    content: [
      {
        title: "Startups",
        text: "Companies who want to keep their presence in customers' minds and attract them to accelerate growth and maximize user acquisitions.",
      },
      {
        title: "E-commerce Platforms",
        text: "Companies that look to improve customer experience by expanding their target users, driving traffic, and increasing conversions.",
      },
      {
        title: "Mobile App Companies",
        text: "Companies are looking to drive more conversions to attract users, increase adoption rates, and increase visibility for long-term growth.",
      },
      {
        title: "Subscription-based Platforms",
        text: "Companies that want to increase retention rates and revenue growth need user acquisition services to formulate strategies for optimizing acquisition funnels.",
      },
    ],
  };

  const commonContent2 = {
    title: "Why work with a Customer Acquisition Company?",
    para: "A user acquisition agency can significantly boost customer growth and profitability. These agencies have the knowledge and resources to offer tailored customer acquisition services and implement strategies to attract, convert, and retain customers more effectively.",
    img: ["what-are-benefits-user-acquisition.webp"],
    imageTitle: 'Benefits of User Acquisition Consultation',
    imageAlt: '',
    content: [
      {
        title: "Targeted Strategies",
        text: "A user acquisition consultation agency offers tailored strategies to conduct market research to identify challenges and potential solutions.",
      },
      {
        title: "Flexible Approach",
        text: "Customer acquisition consultation offers real-time strategies based on market trends and allows for optimizations for optimal results.",
      },
      {
        title: "Continuous Optimization",
        text: "A user acquisition agency focuses on continuous optimization by monitoring campaign performance, analyzing data, and generating valuable insights.",
      },
      {
        title: "Cost Efficiency",
        text: "A user acquisition company offers expertise in optimizing campaigns for maximized results, leading to reduced costs and increased returns.",
      },
    ],
  };
  const commonContent3 = {
    title: "Why choose Octet as your <br />User Acquisition Agency?",
    linkText: ``,
    para: "Octet is a leading user acquisition agency that has been proven to accelerate growth and maximize ROI. Our team offers comprehensive customer acquisition services & leverages data and industry insights to deliver tailored solutions through an impact-driven approach.",
    img: ["why-opt-for-user-acquisition.webp"],
    imageTitle: 'User Acquisition Agency',
    imageAlt: '',
    content: [
      {
        title: "Expertise and Experience",
        text: "Our team has extensive expertise and experience in navigating challenges, identifying opportunities & delivering results to maximize user acquisition efforts.",
      },
      {
        title: "Customized Strategies",
        text: "We consider various factors, such as your target audience, market trends, business goals, etc., to craft a customized strategy for your business.",
      },
      {
        title: "Tailor-made Solutions",
        text: "We conduct in-depth analysis to understand your users’ requirements to design tailor-made solutions for remarkable results.",
      },
      {
        title: "Data-Driven Insights",
        text: "We uncover industry trends, users’ behavior, performance metrics, etc., through our data-driven approach to drive the most effective acquisition tactics.",
      },
    ],
  };
  const commonContent4 = {
    title: "How do we offer Customer <br />Acquisition Services?",
    para: "We leverage our expertise and industry insights to develop data-driven user acquisition strategies, customized to maximize your user acquisition efforts.",
    img: ["how-we-conduct-user-acquisition.webp"],
    imageTitle: 'User Acquisition Approach',
    imageAlt: '',
    content: [
      {
        title: "1. Understanding the user",
        text: "We understand your users' core motivations, pain points, challenges, and preferences and devise a solution for enhanced engagement.",
      },
      {
        title: "3. Inform & Engage",
        text: "We connect with users when they seek more information to solve their problems and engage with them to promote your product.",
      },
      {
        title: "2. Build the Funnel",
        text: "We create a funnel based on the AIDA principle to best communicate with prospective users and understand their perspectives.",
      },
      {
        title: "4. Convert & Activate",
        text: "We finally move to convert prospective visitors into actual users with effective integrated marketing communications.",
      },
    ],
  };
  const commonContent5 = {
    title: "What do you gain from <br />User Acquisition <br />Services?",
    para: "A user acquisition service empowers you to expand your customer base, adapt to industrial trends, and increase your presence in the competitive digital landscape.",
    content: [
      {
        title: "50% User Acquisition*",
        text: "User acquisition helps to effectively implement strategies and tactics, which potentially leads to an increase in user acquisition.",
      },
      {
        title: "10% Increased Conversion*",
        text: "You can optimize your targeting efforts through User Acquisition Consultation Service, resulting in higher conversions.",
      },
      {
        title: "30% Low Acquisition Cost*",
        text: "Customer acquisition consultation leads to cost optimization and low customer acquisition costs.",
      },
    ],
    desc: "**These data are from public sources; we do not claim to owe these stats.",
  };
  const cta = {
    title: "Let us maximize your user <br />acquisition efforts",
  };
  const cta2 = {
    title: "Let us maximize your user <span class='h1-span'>Acquisition Excellence!</span>",
  };
  const cta3 = {
    title: "Ready to acquire more <br />users?",
  };
  const tabs = [
    {
      link: '/angularjs-development/',
      text: 'Angular Development'
    },
    {
      link: '/branding/',
      text: 'Branding'
    },
    {
      link: '/corporate-training-workshop/',
      text: 'UI UX Workshops and Training'
    },
    {
      link: '/data-visualisation/',
      text: 'Dashboards and Data Visualisation'
    },
    {
      link: '/design-audit/',
      text: 'Design Audit '
    },
    {
      link: '/design-systems/',
      text: 'Design System Engineering'
    },
    {
      link: '/digital-transformation/',
      text: 'Digital Transformation'
    },
    {
      link: '/product-development/',
      text: 'Product Development'
    },
    {
      link: '/rapid-prototyping/',
      text: 'Rapid Prototyping'
    },
    {
      link: '/reactjs-development/',
      text: 'ReactJS Development'
    },
    {
      link: '/ui-development/',
      text: 'UI Development'
    },
    {
      link: '/ui-ux-designing/',
      text: 'UI UX Designing'
    },
    {
      link: '/usability-testing/',
      text: 'Usability Testing'
    },
    {
      link: '/user-acquisition-consulting/',
      text: 'User Acquisition Consultation'
    },
    {
      link: '/user-research/',
      text: 'User Research'
    },
    {
      link: '/ux-consulting/',
      text: 'UX Consultation'
    },
    {
      link: '/vuejs-development/',
      text: 'VueJS Development'
    },
    {
      link: '/wordpress-development/',
      text: 'WordPress Development'
    },
  ];
  const projectData = [
    {
      title: "Logo Yazilim",
      desc: "An Accounting Platform for MSME's",
      Industry: "FinTech",
      firstAlt: "",
      firstTitle: "Invoice status dashboard",
      secondAlt: "",
      secondTitle: "Create Invoice",
      WhatWeDid: [
        {
            title: 'UX Consultation',
            link: '/ux-consulting/'
        },
        {
            title: 'UI UX Designing',
            link: '/ui-ux-designing/'
        },
        {
            title: 'UI Development',
            link: '/ui-development/'
        }
      ],
      link: '/project/logo-yazilim/',
      images: ["captain-biz.webp", "captain-biz-2.webp"],
    },
    {
      title: "Fio",
      desc: "A D2C Fashion E-Commerce Platform",
      Industry: "Ecommerce",
      firstAlt: "",
      firstTitle: "Landing Page",
      secondAlt: "",
      secondTitle: "Product List Page",
      WhatWeDid: [
        {
            title: 'Branding',
            link: '/branding/'
        },
        {
            title: 'UI UX Designing',
            link: '/ui-ux-designing/'
        },
        {
            title: 'UI Development',
            link: '/ui-development/'
        }
      ],
      link: '/project/fio/',
      images: ["fio-project.webp", "fio-project-2.webp"],
    },
  ];

  const Questions = [
    "What services does your customer acquisition agency offer?",
    "Do you optimize existing user acquisition campaigns?",
    "How do you measure the success of User acquisition consultation services?",
    "How long can User Acquisition efforts take to deliver results?",
    "How much does your user acquisition consultation service cost?",
  ];
  const clientsTitle =
    "Companies we offered <br />User Acquisition Services";
  const faqDes = `We have answered our clients' common questions about our user acquisition consultation services. However, if you have more queries, feel free to <a href="/contact-us/">contact us</a>. `;

  const faqData = [
    {
      para: [
        `Under our user acquisition consultation services, we offer comprehensive services comprising various user acquisition aspects. Our services include strategic planning, market research, audience targeting, campaign optimization, performance analysis, etc.`,
        `Our customer acquisition agency values transparency and collaboration. We work closely with our clients to understand their requirements and provide data-driven insights.`,
      ],
    },
    {
      para: [
        `As an experienced customer acquisition consulting agency, we can optimize your existing user acquisition campaigns. We do this by thoroughly analyzing your current campaigns, examining KPIs, and identifying areas for improvement.`,
        `By leveraging our expertise, we offer valuable insights to optimize your campaigns and achieve better returns.`,
      ],
    },
    {
      para: [
        `We use certain Key Performance Indicators (KPIs) to measure the success of our customer Acquisition Services. However, the KPIs may differ according to your project requirements.`,
      ],
      list: [
        `<span><h4 class="inline">User Acquisition Cost</h4></span> - It measures the cost of acquiring each customer and the value the business can create from it.`,
        `<span><h4 class="inline">Conversion Rate</h4></span> - It measures the number of users who completed the desired goals.`,
        `<span><h4 class="inline">Return on Investments</h4></span> - It shows the return generated from the investment in acquisition efforts.`,
        `<span><h4 class="inline">Retention Rate</h4></span> - It measures the number of customers engaging with your digital product.`,
      ],
    },
    {
      para: [
        `We offer customized user acquisition strategies for tapping new users. Hence, the timeframe to deliver results varies depending on factors such as target audience, project goals, market trends, etc.`,
        `While it may take a few weeks to several months, the continuous user acquisition consultation should be optimized periodically.`,
      ],
    },
    {
      para: [
        `The cost of our user acquisition services varies depending on factors such as the project’s scope, business complexities, and duration. Hence, we request that you <a target="_blank" title='contact us' rel="noreferrer" href="/contact-us/">contact us</a> for a detailed quotation.`,
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [{
                "@type": "Question",
                "name": "1.  What services does your customer acquisition agency offers?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Under our user acquisition consultation services, we offer comprehensive services comprising various user acquisition aspects. Our services include strategic planning, market research, audience targeting, campaign optimization, performance analysis, etc. 
            
            Our customer acquisition agency values transparency and collaboration. We work closely with our clients to understand their specific requirements and provide data-driven insights."
                }
              },{
                "@type": "Question",
                "name": "2. Do you optimize existing user acquisition campaigns?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, being an experienced customer acquisition consulting agency, we can optimize your existing user acquisition campaigns. We do this by conducting a thorough analysis of your current campaigns, examining KPIs, and identifying areas for improvement. 
            
            By leveraging our expertise, we offer valuable insights to optimize your campaigns and achieve better returns."
                }
              },{
                "@type": "Question",
                "name": "3. How do you measure the success of User acquisition consultation services?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "We use certain Key Performance Indicators (KPIs) to measure the success of our customer Acquisition Services. However, the KPIs may differ according to your project requirements. 
            User Acquisition Cost
            It measures the cost of acquiring each customer and the value the business can create from it. 
            Conversion Rate
            It measures the number of users who completed the desired goals.
            Return on Investments
            It shows the return generated from the investment in acquisition efforts.
            Retention Rate
            It measures the number of customers who keep on engaging with your digital product."
                }
              },{
                "@type": "Question",
                "name": "4. How long can User Acquisition efforts take to deliver results?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "We offer customized user acquisition strategies for tapping new users. Hence, the timeframe to deliver results varies depending on factors such as target audience, project goals, market trends, etc. 
            
            While it may take a few weeks to several months, the user acquisition consultation is continuous and should be optimized periodically."
                }
              },{
                "@type": "Question",
                "name": "5. How much does your user acquisition consultation service cost?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The cost of our user acquisition services varies depending on factors such as the project’s scope, business complexities, and duration. Hence, we request that you contact us for a detailed quotation."
                }
              }]
            }            
          `}
        </script>
      </Helmet>
      <Layout>
        <div className="service-detail-page">
          <Banner content={banner} page={"service"} />
          <WhatServiceDo data={whatServiceContent} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent} mode={"light"} />
          <CommonServiceComponent data={commonContent2} mode={"dark"} />
          <CommonServiceComponent data={commonContent3} mode={"light"} />
          <Cta data={cta} />
          <Clients title={clientsTitle} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent4} mode={"light"} />
          <section className="project-section lg:py-[200px] md:py-[120px] py-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
            <div className="container">
              <h2 className="lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] tracking-[.01em]">
                Proven User Acquisition <span className="h1-span">Consultation Outcomes</span>
              </h2>
            </div>
            {projectData.map((data, index) => (
              <div key={index}>
                <ProjectSection num={index} lastLength={projectData} data={data} />
              </div>
            ))}
          </section>
          <Testimonials title={`Client’s take on <span class='h1-span'>Our Solutions</span>`} />
          <Cta data={cta2} />
          <CommonServiceComponent data={commonContent5} condition mode={"light"} />
          <ServiceTab tabs={tabs} mode={"split"} />
          <Faq Questions={Questions} section des={faqDes} link faqData={faqData} />
          <Cta mode="grey" data={cta3} />
          {/* <KnowMoreSection /> */}
        </div>
      </Layout>
    </>
  );
};

export default ServiceDetail;

export const Head = () => (
  <Seo title="User Acquisition Agency | Customer Acquisition Services" description="We optimize your user acquisition effort with our user acquisition services. Our customer acquisition agency offers strategic consultation to maximize your ROI." />
)